// 薪酬-个税
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 人员报送（个税申报）-报税周期的报税主体列表
export async function getTaxpaySubjectListByPeriod(params) {
  return request(`${WAGE}/taxpaySubject/getTaxpaySubjectListByPeriod`, METHOD.GET, params)
}

// 人员报送-获取全部报税周期
export async function getCurrentAllPeriod(params) {
  return request(`${WAGE}/taxpaySubject/getCurrentAllPeriod`, METHOD.GET, params)
}

// 人员报送-尚未分配报税主体的员工数量
export async function unassignedStaffNum(params) {
  return request(`${WAGE}/taxpaySubject/unassignedStaffNum`, METHOD.GET, params)
}

// 人员报送-核对人员-报送汇总信息
export async function getPersonnelStatistics(params) {
  return request(`${WAGE}/taxpaySubject/getPersonnelStatistics`, METHOD.GET, params)
}

// 人员报送-核对人员-报送人员列表
export async function getPersonnelSubmissionList(params) {
  return request(`${WAGE}/taxpaySubject/getPersonnelSubmissionList`, METHOD.GET, params)
}

// 人员报送-核对人员-减员人员列表
export async function getPersonnelReducingList(params) {
  return request(`${WAGE}/taxpaySubject/getPersonnelReducingList`, METHOD.GET, params)
}

// 人员报送-核对人员-报税全员列表
export async function getPersonnelTaxFilingList(params) {
  return request(`${WAGE}/taxpaySubject/getPersonnelTaxFilingList`, METHOD.GET, params)
}

// 人员报送-核对人员-报税主体变更人员列表
export async function getSubjectChangedList(params) {
  return request(`${WAGE}/taxpaySubject/getSubjectChangedList`, METHOD.GET, params)
}

// 人员报送-核对人员-导出报送人员列表
export async function exportPersonnelSubmissionList(params) {
  return request(`${WAGE}/taxpaySubject/exportPersonnelSubmissionList`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 人员报送-核对人员-导出减员人员列表
export async function exportPersonnelReducingList(params) {
  return request(`${WAGE}/taxpaySubject/exportPersonnelReducingList`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 人员报送-核对人员-导出报税全员列表
export async function exportPersonnelTaxFilingList(params) {
  return request(`${WAGE}/taxpaySubject/exportPersonnelTaxFilingList`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 人员报送-核对人员-导出报税主体变更人员列表
export async function exportSubjectChangedList(params) {
  return request(`${WAGE}/taxpaySubject/exportSubjectChangedList`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 人员报送-核对人员-移除报税人员
export async function removeStaff(params) {
  return request(`${WAGE}/taxpaySubject/removeStaff`, METHOD.GET, params)
}

// 人员报送-核对人员-导入员工报送信息
export async function importTaxFilingInfoList(params, config) { // config: { loading: false }
  return request(`${WAGE}/individualIncomeTax/importTaxFilingInfoList`, METHOD.POST, params, config)
}

// 个税申报-查看申报情况
export async function getTaxPaymentListByPeriod(params) {
  return request(`${WAGE}/taxpaySubject/getTaxPaymentListByPeriod`, METHOD.GET, params)
}
// 提示信息是否存在
export async function noticeResult(params) {
  return request(`${WAGE}/taxpaySubject/noticeResult`, METHOD.GET, params)
}
// 人员申报-导入申报信息
export async function importTaxpayStaffPaymentInfo(params, config) {
  console.log('参数importTaxpayStaffPaymentInfo', params, config)
  return request(`${WAGE}/taxpaySubject/importTaxpayStaffPaymentInfo`, METHOD.POST, params, config)
}
// 人员申报-导出申报信息
export async function exportTaxpayStaffPaymentInfo(params) {
  return request(`${WAGE}/taxpaySubject/exportTaxpayStaffPaymentInfo`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 人员申报-忽略操作
export async function ignoreStaff(params) {
  return request(`${WAGE}/taxpaySubject/ignoreStaff`, METHOD.POST, params)
}
// 人员申报-批量保存
export async function ignoreBatch(params) {
  return request(`${WAGE}/taxpaySubject/saveTaxpayPerson`, METHOD.POST, params)
}
// 人员申报-减员添加
export async function reducePersonAdd(params) {
  return request(`${WAGE}/taxpaySubject/reducePersonAdd`, METHOD.GET, params)
}

// 导出下载模板
export async function getImportTemplate(params) {
  return request(`${WAGE}/taxpaySubject/getImportTemplate`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
